import React from 'react';
import { Link } from 'react-router-dom';
import '../css/Footer.css';

const Footer = () => (
  <footer className="site-footer">
    <div className="row column">
      <nav className="footer-nav">
        <Link to="/">Home</Link> |<Link to="/services">Services</Link> |<Link to="/aboutus">About Us</Link> |
        <Link to="/contact">Contact</Link>
      </nav>
      <nav className="footer-nav-line-2">
        <Link to="/training">Trainings</Link> |<Link to="/blog">Blogs</Link> |<Link to="/news">News</Link>
      </nav>
    </div>
    <div className="row column">
      <a href="https://www.linkedin.com/company/syntax-nebula" target="_blank" rel="noopener noreferrer">
        <img src="/images/in.png" alt="linked-in" width="30" />
      </a>
    </div>
    <div className="row column">
      <div className="footer-legal">
        &copy;Szegedi Csaba
        <br />
        All rights reserved
        <br />
      </div>
      <div className="footer-info">
        <a href="/gdpr">Privacy policy</a>
      </div>
      <div className="footer-info">We use cookies on the website only for secure user login.</div>
      <div className="footer-info">Images created with ChatGPT. Video created with Runway.</div>
    </div>
  </footer>
);

export default Footer;
