import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { signOut } from 'aws-amplify/auth';
import MenuButton from './MenuButton';
import '../css/MenuButton.css';
import '../css/PageTemplate.css';
import { useAuth } from '../contexts/AuthContext';
import { fetchAuthSession } from 'aws-amplify/auth';
import awsExports from '../config/aws-exports';

const Header = () => {
  const navigate = useNavigate();
  const { isAuthenticated, userLabel, authenticateUser } = useAuth();
  const [sandboxSignInUrl, setSandboxSignInUrl] = useState('');
  const [accountId, setAccountId] = useState('');

  const [initialLoop, setInitialLoop] = useState(true);
  const [playVideo, setPlayVideo] = useState(true);
  const [displayVideo, setDisplayVideo] = useState(0);
  const [imageOpacity, setImageOpacity] = useState(0);
  const [isVideoVisible, setIsVideoVisible] = useState(true);
  const [videoError, setVideoError] = useState(false);
  const videoRef = useRef(null);

  const [videoSources] = useState([
    'https://syntaxnebula-media.s3.eu-central-1.amazonaws.com/syntaxnebula_banner_left_up.mp4',
    'https://syntaxnebula-media.s3.eu-central-1.amazonaws.com/syntaxnebula_banner_right_up.mp4',
  ]);
  const [videoSource, setVideoSource] = useState(videoSources[0]);
  const [videoIndex, setVideoIndex] = useState(0);

  const updateVideoSource = () => {
    if (isVideoVisible) {
      const nextIndex = (videoIndex + 1) % videoSources.length;
      setVideoIndex(nextIndex);
      setVideoSource(videoSources[nextIndex]);
      setVideoError(false);
    }
  };

  const playVideoSafely = async (videoElement) => {
    try {
      await videoElement.play();
    } catch (error) {
      console.error('Error trying to play video:', error.message);
      setVideoError(true);
    }
  };

  const pauseVideoSafely = async (videoElement) => {
    try {
      await videoElement.pause();
    } catch (error) {
      console.error('Error trying to pause video:', error.message);
      setVideoError(true);
    }
  };

  const loadVideoSafely = async (videoElement) => {
    try {
      await videoElement.load();
    } catch (error) {
      console.error('Error trying to load video:', error.message);
      setVideoError(true);
    }
  };

  const handleVideoEnd = async () => {
    const videoElement = videoRef.current;
    if (isVideoVisible) {
      setImageOpacity(1);
      setTimeout(() => {
        if (videoElement) {
          updateVideoSource();
          pauseVideoSafely(videoElement);
          loadVideoSafely(videoElement);
          playVideoSafely(videoElement);
          videoElement.playbackRate = 0.5;
          setImageOpacity(0);
          setPlayVideo(true);
        }
      }, 20000);
    }
  };

  useEffect(() => {
    if (initialLoop && isVideoVisible) {
      setImageOpacity(1);
      setPlayVideo(false);
      setVideoSource(videoSources[0]);
      setTimeout(() => {
        if (videoRef.current) {
          playVideoSafely(videoRef.current);
          videoRef.current.playbackRate = 0.5;
          setImageOpacity(0);
          setDisplayVideo(1);
        }
      }, 5000);
    }
    setInitialLoop(false);
  }, [isVideoVisible, initialLoop, videoSources]);

  useEffect(() => {
    const videoElement = videoRef.current;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVideoVisible(true);
          playVideoSafely(videoElement);
        } else {
          setIsVideoVisible(false);
          pauseVideoSafely(videoElement);
        }
      },
      {
        threshold: 0.25,
      },
    );

    if (videoElement) {
      observer.observe(videoElement);
    }

    return () => {
      if (videoElement) {
        observer.unobserve(videoElement);
      }
    };
  }, []);

  async function handleSignOut() {
    try {
      await signOut();
      authenticateUser(false, '');
      localStorage.setItem('isAuthenticated', false);
      navigate('/');
    } catch (error) {
      console.log('error signing out: ', error);
    }
  }

  useEffect(() => {
    function base64UrlDecode(input) {
      // Replace characters according to base64url specifications
      let base64 = input.replace(/-/g, '+').replace(/_/g, '/');

      // Pad string with '=' characters to make the length a multiple of 4
      switch (base64.length % 4) {
        case 0:
          break; // No pad chars in this case
        case 2:
          base64 += '==';
          break; // Two pad chars
        case 3:
          base64 += '=';
          break; // One pad char
        default:
          throw new Error('Illegal base64url string!');
      }

      const raw = window.atob(base64);
      return decodeURIComponent(
        raw
          .split('')
          .map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join(''),
      );
    }

    function decodeJWT(token) {
      const parts = token.split('.');
      if (parts.length !== 3) {
        throw new Error('The token is invalid');
      }

      const header = JSON.parse(base64UrlDecode(parts[0]));
      const payload = JSON.parse(base64UrlDecode(parts[1]));

      return { header, payload };
    }

    async function decodeAndAuthenticate() {
      try {
        const session = await fetchAuthSession();
        if (!session || !session.tokens || !session.tokens.idToken) {
          authenticateUser(false, '');
          return;
        }

        const idToken = session.tokens.idToken.toString();
        const decodedToken = decodeJWT(idToken);
        const username = decodedToken.payload.name;
        authenticateUser(true, username);
      } catch (error) {
        console.error('Error checking auth status:', error);
        authenticateUser(false, '');
      }
    }

    async function getSandboxAccountForUser() {
      if (localStorage.getItem('isAuthenticated') === 'false' || localStorage.getItem('isAuthenticated') === null) {
        return;
      }
      const apiUrl = awsExports.GET_SANDBOX_API_URL;
      const fullUrl = `${apiUrl}sandbox`;
      try {
        const { idToken } = (await fetchAuthSession()).tokens ?? {};
        const response = await fetch(fullUrl, {
          method: 'GET',
          headers: {
            Authorization: idToken.toString(),
          },
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        } else if (response.status === 204) {
          setSandboxSignInUrl('');
          return;
        }
        const data = await response.json();
        setAccountId(data.accountId);
        setSandboxSignInUrl('https://console.aws.amazon.com');
      } catch (error) {
        setSandboxSignInUrl('');
        console.error('There has been a problem with sandbox account information retrieval:', error);
      }
    }

    decodeAndAuthenticate();
    getSandboxAccountForUser();
  }, [isAuthenticated, authenticateUser]);

  return (
    <header id="siteHeader" className="site-header">
      <video
        muted
        ref={videoRef}
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
          objectFit: 'cover',
          zIndex: 0,
          display: displayVideo ? 'block' : 'none',
        }}
        onEnded={handleVideoEnd}
        autoPlay={playVideo}
        onError={() => {
          console.error('Error loading or playing video');
          setVideoError(true);
        }}
      >
        <source src={videoSource} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      <img
        src="/images/syntaxnebula_banner_upscaled.webp"
        alt="Syntax Nebula header"
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
          objectFit: 'cover',
          zIndex: 0,
          opacity: videoError ? 1 : imageOpacity,
          transition: 'opacity 5s ease',
          filter: 'brightness(90%)',
        }}
        className="responsive-banner"
      />
      {isAuthenticated ? (
        <>
          <span className="user-label"> Welcome, {userLabel}!</span>
          <button onClick={handleSignOut} className="signout-button">
            Sign out
          </button>
          {sandboxSignInUrl && (
            <>
              <span className="sandbox-label"> Sandbox account: [ {accountId} ] </span>
              <a href={sandboxSignInUrl} className="sandbox-button" target="_blank" rel="noopener noreferrer">
                {' '}
                Console{' '}
              </a>
            </>
          )}
        </>
      ) : (
        <Link to="/signin" className="signin-button">
          Sign in
        </Link>
      )}
      <a href="/">
        <img className="logo" src="/images/logo.png" alt="Logo" />
      </a>
      <MenuButton />
    </header>
  );
};

export default Header;
