import React, { useState, useEffect, Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import './css/General.css';
import './css/PageTemplate.css';
import './css/HomeStory.css';
import './css/FontSize.css';
import './css/DivRowCol.css';
import { AuthProvider } from './contexts/AuthContext';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import MyAuthenticator from './components/Authenticator';
const HomePage = lazy(() => import('./pages/HomePage'));
const ServicesPage = lazy(() => import('./pages/Services'));
const AboutUsPage = lazy(() => import('./pages/AboutUs'));
const TrainingsPage = lazy(() => import('./pages/Trainings'));
const BlogsPage = lazy(() => import('./pages/Blogs'));
const NewsPage = lazy(() => import('./pages/News'));
const ContactPage = lazy(() => import('./pages/Contact'));
const GdprPage = lazy(() => import('./pages/Gdpr'));

export const PresentationContext = React.createContext(null);

function App() {
  const [isFullscreen, setIsFullscreen] = useState(false);

  useEffect(() => {
    function handleFullscreenChange() {
      console.log('Fullscreen changed:', !!document.fullscreenElement);
      setIsFullscreen(!!document.fullscreenElement);
    }

    document.addEventListener('fullscreenchange', handleFullscreenChange);

    // Cleanup
    return () => document.removeEventListener('fullscreenchange', handleFullscreenChange);
  }, []);

  return (
    <div className="site-container">
      <Router>
        <HelmetProvider>
          <Helmet>
            <meta
              name="description"
              content="Virtual and in person customized AWS trainings. More info on https://syntaxnebula.com or contact us in email at info@syntaxnebula.com."
            />
            <meta name="robots" content="index,follow" />
            <meta name="google" content="notranslate" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <link href="https://fonts.googleapis.com/css?family=Open+Sans&display=swap" rel="stylesheet" />
          </Helmet>
        </HelmetProvider>
        <Suspense fallback={<div>Loading...</div>}>
          <AuthProvider>
            <PresentationContext.Provider value={{ isFullscreen, setIsFullscreen }}>
              {!isFullscreen && <Header />}
              <main className="content">
                <Routes>
                  <Route path="/" element={<HomePage />} />
                  <Route path="/services" element={<ServicesPage />} />
                  <Route path="/aboutus" element={<AboutUsPage />} />
                  <Route path="/training/*" element={<TrainingsPage />} />
                  <Route path="/blog/*" element={<BlogsPage />} />
                  <Route path="/news/*" element={<NewsPage />} />
                  <Route path="/contact" element={<ContactPage />} />
                  <Route path="/signin" element={<MyAuthenticator />} />
                  <Route path="/gdpr" element={<GdprPage />} />
                </Routes>
              </main>
              {!isFullscreen && <Footer />}
            </PresentationContext.Provider>
          </AuthProvider>
        </Suspense>
      </Router>
    </div>
  );
}

export default App;
