import React, { useState } from 'react';

const MenuButton = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <>
      <div className={`menu-container ${isMenuOpen ? 'active' : ''}`} id="menu">
        <a href="/" onClick={closeMenu}>
          Home
        </a>{' '}
        |
        <a href="/services" onClick={closeMenu}>
          Services
        </a>{' '}
        |
        <a href="/aboutus" onClick={closeMenu}>
          About Us
        </a>{' '}
        |
        <a href="/contact" onClick={closeMenu}>
          Contact
        </a>
      </div>
      <div className={`menu-container-line-2 ${isMenuOpen ? 'active' : ''}`} id="menu">
        <a href="/training" onClick={closeMenu}>
          Trainings
        </a>{' '}
        |
        <a href="/blog" onClick={closeMenu}>
          Blogs
        </a>{' '}
        |
        <a href="/news" onClick={closeMenu}>
          News
        </a>
      </div>
      <div className="hamburger" id="hamburger" onClick={toggleMenu}>
        &#9776;
      </div>
    </>
  );
};

export default MenuButton;
